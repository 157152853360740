import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useNewSortingDebt = () => {
  const testCookieName = 'dx-new-sorting-debt-v3';

  const variants = {
    '0': 'new-sorting-debt-v3',
    '1': 'old-sorting-debt-v3'
  };

  const resultIsNewSortingDebt = () => {
    const currentResult = getCookie(testCookieName, false);

    if (!currentResult) return false;

    return currentResult.includes(variants[0]);
  };

  return {
    resultIsNewSortingDebt,
  };
};

export default useNewSortingDebt;
