import Button from '@libs/atlas/components/Button';
import Conditional from '@libs/atlas/components/misc/Conditional';
import Text from "@libs/atlas/components/Text";
import { IconX, IconInfo } from '@libs/atlas/components/icons/default';

import './styles.scss'
import { MouseEvent, useEffect, useState } from 'react';

interface LogoutModalProps {
  show: boolean;
  onLogout: (e: MouseEvent<HTMLButtonElement>) => void
  onNotLogout: (e: MouseEvent<HTMLButtonElement>) => void
}

const LogoutModal = ({
  show,
  onLogout,
  onNotLogout
}: LogoutModalProps) => {
  const [isOpen, toggleOpen] = useState<boolean>(false)

  function onClickClose() {
    toggleOpen(prev => !prev)
  }

  useEffect(() => {
    toggleOpen(show)
  }, [show])

  return (
    <Conditional
      condition={isOpen}
      renderIf={
        <div className={`logout-modal__backdrop active`}>
          <div className="logout-modal__container">
            <span className="logout-modal__close" onClick={onClickClose}>
              <IconX />
            </span>

            <div className='logout-modal__body'>
              <div className='logout-modal__header'>
                <IconInfo />

                <Text weight='bold' color='common' variant='heading-three'>
                  Tem certeza que deseja sair?
                </Text>
              </div>

              <div className='logout-modal__content'>
                <Text weight='regular' color='medium'>
                  Você pode optar por sair da página e continuar logado,
                  assim não será necessário digitar sua senha quando precisar
                  acessar a Acordo Certo novamente.
                </Text>
              </div>

              <div className='logout-modal__footer'>
                <Button type='secondary' onClick={onLogout}>Sair</Button>
                <Button onClick={onNotLogout}>Sair e continuar logado</Button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default LogoutModal
