import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Conditional from '@libs/atlas/components/misc/Conditional';
import PrivatePageTemplate from '@common/templates/PrivatePage';
import { DebtsProvider } from '@common/contexts/DebtsContext/DebtsProvider';
import useAuth from '@common/hooks/useAuth';
import { useUserStore } from '@common/store/useUserStore';
import PageLoader from '@common/components/PageLoader';
import { useRedline } from '@libs/redline';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { base64Decrypt } from '@libs/utils/helpers/base64';
import constants from '@common/constants';
import useUserAuthentication from '@admission/hooks/useUserAuthentication';
import usePushNotification from '@common/hooks/usePushNotification';
import useApiLoginEnrichment from '@common/apis/useAPILoginEnrichment';
import useGTM from '@common/hooks/useGTM';
import useGetOffers from '@common/hooks/useOffers';

export default function PrivateRoutes() {
  const user = useUserStore((state) => state.user);
  const { addCookieRLTracksAuth, setRLTracksAuthCookie } =
    useUserAuthentication();
  const { apiLoginEnrichment } = useApiLoginEnrichment();
  const { isAuthenticated } = useAuth();
  const { track: trackPushNotification } = usePushNotification();
  const { track: trackRedline } = useRedline();
  const GTM = useGTM()

  useEffect(() => {
    if (isAuthenticated) {
      trackPushNotification(user);
      GTM.trigger.loginWithSuccess()

      const authCookie = getCookie(constants.redline.rl_tracks_auth, false);
      if (authCookie) {
        const rLTracksAuthData = JSON.parse(base64Decrypt(authCookie));

        apiLoginEnrichment.send();

        trackRedline.userAuthentication.userAuthenticated({
          customerId: user.documento,
          correlationId: rLTracksAuthData.correlationId,
          authenticationType: rLTracksAuthData.authenticationType,
        });

        addCookieRLTracksAuth('cached-sign-in');
      } else {
        setRLTracksAuthCookie();
      }

      trackRedline.userAuthentication.userIdentified({
        document: user.documento,
      });
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <PageLoader />;
  }

  return (
    <Conditional
      condition={!!isAuthenticated}
      renderIf={
        <DebtsProvider>
          <PrivatePageTemplate firstName={user.primeiroNome} fullName={user.nome}>
            <Outlet />
          </PrivatePageTemplate>
        </DebtsProvider>
      }
    />
  );
}
