import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Conditional from '../misc/Conditional';
import { IconLoader } from '../icons/default';
import './styles.scss';

type ButtonProps = (
  | {
      as: 'a';
      href: string;
    }
  | {
      as?: 'button';
      href?: never;
    }
) & {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  color?: 'default';
  disabled?: boolean;
  expand?: 'x';
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  size?: 'large' | 'medium';
  type?: 'primary' | 'secondary';
  target?: string;
  negative?: boolean;
  btnText?: boolean;
  className?: string;
  borderWidth?: string;
};

const Button: React.FC<ButtonProps> = ({
  as,
  children = 'Button component',
  color = 'default',
  disabled = false,
  expand,
  href,
  htmlType = 'button',
  loading = false,
  onClick,
  size = 'medium',
  type = 'primary',
  target,
  negative = false,
  btnText = false,
  className,
  borderWidth = 'medium',
  ...props
}) => {
  const buttonClasses = classNames('ac-btn', {
    [`ac-btn--type-${type}`]: !!type,
    [`ac-btn--size-${size}`]: !!size,
    [`ac-btn--expand-${expand}`]: !!expand,
    [`ac-btn--color-${color}`]: !!color,
    'ac-btn--negative': !!negative,
    'ac-btn--disabled': !!disabled,
    'ac-btn--loading': !!loading,
    'btn-text': !!btnText,
    [`ac-btn--border-${borderWidth}`]: !!borderWidth,
    [className ?? '']: typeof className === 'string'
  });

  function handleClick(e: any) {
    if (!disabled && !!onClick) onClick(e);
  }

  if (as === 'a') {
    return (
      <a className={buttonClasses} href={href} target={target} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button
      className={buttonClasses}
      onClick={handleClick}
      type={htmlType}
      disabled={disabled || loading}
      {...props}
    >
      <Conditional
        condition={loading}
        renderIf={<IconLoader />}
        renderElse={children}
      />
    </button>
  );
};

export default Button;
