import { unmask } from 'remask';
import { SortingDebtReqProps } from './types';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';

export const transformTrackPayload = (
  { customerIdHash, document }: SortingDebtReqProps,
  isOnAtivosExp: boolean
) => ({
  customerIdHash: customerIdHash,
  documento: unmask(document),
  project: 'react',
  session_id: getSessionID(),
  anonymous_id: getAnonymousID(),
  application_source: 'ac-app',
  ...(isOnAtivosExp && {
    parameters: {
      variant: 'ativos_groupped_debt',
    },
  }),
});
