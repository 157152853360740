import acInterceptor from '../acInterceptor';
import { useRequest } from 'alova';
import { transformTrackPayload } from './transformPayload';
import {
  SortingDebtReqProps,
  SortingDebtResponse,
  SortingDebtInvalidateCacheProps,
} from './types';

import useNewSortingDebt from '@common/abtest/experiments/useNewSortingDebt';
import useAtivosGroupedDebts from '@common/abtest/experiments/useAtivosGroupedDebts';
import { isLocal } from '@libs/utils/helpers/isLocal';

const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
};

const useApiSortingDebt = () => {
  const { resultIsNewSortingDebt } = useNewSortingDebt();
  const isDev = import.meta.env.VITE_ENV === 'dev';
  const isNewSortingDebtEndpoint = resultIsNewSortingDebt() || isLocal() || isDev;

  const baseURL = isNewSortingDebtEndpoint
    ? import.meta.env.VITE_NEW_SORTING_DEBT_API_URL
    : import.meta.env.VITE_SORTING_DEBT_API_URL;

  const { resultIsGroupedDebts } = useAtivosGroupedDebts();
  const shouldUseGroupedDebts = resultIsGroupedDebts();

  const apiSortingDebtTrack = useRequest(
    (payload: SortingDebtReqProps) =>
      acInterceptor({ baseURL }).Post<SortingDebtResponse>(
        '/sorting-debt/v1/app/debt/track',
        transformTrackPayload(payload, shouldUseGroupedDebts),
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiSortingDebtInvalidateCache = useRequest(
    (payload: SortingDebtInvalidateCacheProps) =>
      acInterceptor({ baseURL }).Post<SortingDebtResponse>(
        '/sorting-debt/v1/public/debt/invalidate',
        payload,
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  return { apiSortingDebtTrack, apiSortingDebtInvalidateCache };
};

export default useApiSortingDebt;
