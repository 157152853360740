import untilLoad from "@libs/utils/helpers/untilLoad"

const usePushNotification = () => {
  const track = async (user: any) => {
    const maxTimeoutToLoadScripts = 6000;

    const hermesServiceLoaded = await untilLoad(
      () => window['hermesService' as any]?.isInitialized,
      maxTimeoutToLoadScripts
    );

    if (hermesServiceLoaded) {
      const hermes = window['hermesService' as any];
      hermes.pushOn(0);
      hermes.setUserId(user?.documento);
    };
  };



  return {
    track,
  };
};

export default usePushNotification;
