import LogoutModal from '@common/components/LogoutModal';
import {
  isFullFooter,
  isFullHeader,
  isSimpleHeader,
  pageNames,
} from '@common/routes/pageNames';
import {
  Button,
  Drawer,
  Header,
  IconChevronLeft,
  IconVelocimeter,
  IconX,
  LogoBadgetAC,
  LogoTertiaryAC,
  NavbarVertical,
  NotificationsBar,
  ProfileNav,
  Text,
} from '@consumidor-positivo/aurora';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { dateToLocale } from '@libs/utils/helpers/dateToLocale';
import { ReactNode, lazy } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePrivatePageHook } from './hooks/index';
import './styles.scss';

const Footer = lazy(() => import('@common/components/Footer'));

type PrivatePageTemplateProps = {
  children: ReactNode;
  firstName: string | undefined;
  fullName: string | undefined;
};

export default function PrivatePageTemplate({
  children,
  firstName,
  fullName,
}: PrivatePageTemplateProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    drawerOpen,
    handleOpenDrawer,
    onClickBackMenu,
    onClickLogout,
    onClickLogoutModal,
    onClickNotLogout,
    partnerImage,
    showLogoutModal,
  } = usePrivatePageHook();

  return (
    <>
      <Conditional
        condition={isFullHeader(location.pathname)}
        renderIf={
          <Header.Root position="fixed">
            <Header.Button onClick={() => navigate(pageNames.myDebts.path)}>
              <Header.Logo
                renderDesktop={<LogoTertiaryAC />}
                renderMobile={<LogoBadgetAC />}
              />
            </Header.Button>
            <Header.Navigation>
              <Header.Navbar
                data={dataSourceNavbar}
                renderItem={(item) => (
                  <Header.NavbarLink
                    key={item.name}
                    active={item.active}
                    name={item.name}
                    onClick={item.onClick}
                  />
                )}
              />
              <Header.Actions divider>
                <Header.Profile
                  onClickMenu={() => handleOpenDrawer('menu')}
                  onClickNotifications={() => handleOpenDrawer('notification')}
                  fullName={fullName || ''}
                />
              </Header.Actions>
            </Header.Navigation>

            <Drawer
              isOpen={drawerOpen.menu}
              handleOpen={() => handleOpenDrawer('menu')}
              renderHeader={
                <ProfileNav name={firstName || ''} fullName={fullName || ''} />
              }
              renderContent={
                <NavbarVertical
                  data={dataSourceNavbarVertical}
                  renderItem={(link) => {
                    return (
                      <NavbarVertical.Link
                        key={link.name}
                        active={link.active}
                        Icon={link.Icon}
                        name={link.name}
                        onClick={() => {
                          handleOpenDrawer('menu');
                          link.onClick();
                        }}
                      />
                    );
                  }}
                  renderActions={() => (
                    <Button
                      type="outlined"
                      expand="x"
                      onClick={onClickLogoutModal}
                    >
                      Sair
                    </Button>
                  )}
                />
              }
            />
            <Drawer
              isOpen={drawerOpen.notification}
              handleOpen={() => handleOpenDrawer('notification')}
              renderHeader={
                <Text as="h3" variant="heading-small" weight="bold">
                  Notificações
                </Text>
              }
              renderContent={
                <NotificationsBar.Root
                  renderRecents={() => (
                    <NotificationsBar.List
                      title="Recentes"
                      dataSource={[
                        {
                          title: 'Você já viu seu Score?',
                          Icon: <IconVelocimeter color="info" />,
                          onClick: () => navigate(pageNames.myScore.path),
                          createdAt: dateToLocale(),
                        },
                      ]}
                      renderItem={(item) => (
                        <NotificationsBar.Link
                          title={item.title}
                          Icon={item.Icon}
                          createdAt={item.createdAt}
                          onClick={() => {
                            handleOpenDrawer('notification');
                            item.onClick();
                          }}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </Header.Root>
        }
      />

      <Conditional
        condition={isSimpleHeader(location.pathname)}
        renderIf={
          <Header.Root position="fixed">
            <Header.Button onClick={onClickBackMenu}>
              <IconChevronLeft />
            </Header.Button>
            <Header.Badges>
              <Header.Button onClick={() => navigate(pageNames.myDebts.path)}>
                <LogoBadgetAC />
              </Header.Button>
              <Conditional
                condition={!!params?.partner}
                renderIf={
                  <div className="au-logo">
                    <img
                      className="img-badges-partner"
                      src={partnerImage}
                      alt={`Logo ${params.partner}`}
                    />
                  </div>
                }
              />
            </Header.Badges>
            <Conditional
              condition={location.pathname.includes('acordo-fechado')}
              renderIf={
                <Header.Button onClick={() => navigate(pageNames.myDebts.path)}>
                  <IconX />
                </Header.Button>
              }
            />
          </Header.Root>
        }
      />

      <Conditional
        condition={
          !isFullHeader(location.pathname) && !isSimpleHeader(location.pathname)
        }
        renderIf={
          <Header.Root position="fixed">
            <Header.Button onClick={() => navigate(pageNames.myDebts.path)}>
              <Header.Logo>
                <LogoTertiaryAC />
              </Header.Logo>
            </Header.Button>
          </Header.Root>
        }
      />
      <main role="main" className="private-page">
        <div className="private-page__container">{children}</div>

        <LogoutModal
          show={showLogoutModal}
          onLogout={onClickLogout}
          onNotLogout={onClickNotLogout}
        />
      </main>
      <Conditional
        condition={isFullFooter(location.pathname)}
        renderIf={<Footer />}
      />
    </>
  );
}