import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useDebtCardRedesignReneg = () => {
  const testCookieName = 'dx-debt-card-redesign-reneg';

  const variants = {
    '0': 'a-debt-card-reneg',
    '1': 'b-debt-card-reneg',
    '2': 'c-debt-card-reneg',
  };

  const currentResult = getCookie(testCookieName, false);

  if (!currentResult) return { resultIsCardA: true };

  return {
    resultIsCardA: !!currentResult?.includes(variants[0]),
    resultIsCardB: !!currentResult?.includes(variants[1]),
    resultIsCardC: !!currentResult?.includes(variants[2]),
  };
};

export default useDebtCardRedesignReneg;