import { useDrawer } from '@consumidor-positivo/aurora';
import { getPartnerImage } from '@libs/utils/helpers/getPartnerImage';
import { useParams } from 'react-router-dom';
import { usePrivatePagesHandlers } from './useHandlers';
import { usePrivatePagesStates } from './useStates';

export const usePrivatePageHook = () => {
  const params = useParams();
  const partnerImage = getPartnerImage(params?.partner);

  const {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    showLogoutModal,
    setShowLogoutModal,
  } = usePrivatePagesStates();
  const {
    onClickBackMenu,
    onClickLogout,
    onClickNotLogout,
    onClickLogoutModal,
  } = usePrivatePagesHandlers({ setShowLogoutModal });
  const { drawerOpen, handleOpenDrawer } = useDrawer({
    menu: false,
    notification: false,
  });

  return {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    drawerOpen,
    handleOpenDrawer,
    onClickBackMenu,
    onClickLogout,
    onClickNotLogout,
    onClickLogoutModal,
    partnerImage,
    showLogoutModal,
  };
};
