import { addXAuthTokenHeaders } from '@common/apis/customAddHeaders';
import customStorageAdapter from '@common/apis/customStorageAdpter';
import { createAlova } from 'alova';
import GlobalFetch from 'alova/GlobalFetch';
import ReactHook from 'alova/react';
import acDatadog from './acDatadog';

interface acInterceptorProps {
  baseURL: string;
  headersBeforeRequest?: object;
  addXAuthToken?: boolean;
}

/**
 * Creates an instance of Alova with the provided configurations.
 * @property {string} baseURL The base URL for the requests of the Alova instance.
 * @property {Object} [headersBeforeRequest] - Custom headers to be included in requests.
 * @property {boolean} [addXAuthToken = true] - Indicates whether the X-Auth-Token header should be added to the requests. Default is true.
 * @returns {AlovaInstance} The created Alova instance.
 */
const acInterceptor = ({
  baseURL,
  headersBeforeRequest,
  addXAuthToken = true,
}: acInterceptorProps) =>
  createAlova({
    statesHook: ReactHook,
    requestAdapter: GlobalFetch(),
    baseURL,
    storageAdapter: customStorageAdapter,
    responded: {
      onSuccess: async (response) => {
        const details = {
          bodyUsed: response.bodyUsed,
          status: response.status,
          statusText: response.statusText,
          type: response.type,
          url: response.url,
        };

        const json = await response.json().catch(() => ({}));

        if (response.status >= 400) {
          const errorContext = { ...json, ...details };
          acDatadog.addErrorContextProperty("error_data", errorContext)

          throw errorContext;
        };

        acDatadog.clearErrorContext();

        return json;
      },

      onError: (error: any, method: any) => {
        acDatadog.clearErrorContext();

        return
      },
    },
    async beforeRequest(method) {
      if (baseURL?.includes('geminio')) return;

      method.config.headers = { ...headersBeforeRequest };
      if (addXAuthToken) await addXAuthTokenHeaders(method);
    },
  });

export default acInterceptor;
