import { FormattedDebt } from '@common/contexts/DebtsContext/types';
import { getDebtDiscount, createPartnerFilter } from './helpers';
import { DebtFilter } from './types';

const negative: DebtFilter = {
  id: 'negative',
  label: 'Dívidas negativadas',
  filterFn: (debts: FormattedDebt[]) =>
    debts.filter((debt) => debt.debtIsNegative),
  desc: 'Aqui você consegue ver suas dívidas mais recentes que <strong>surgiram entre os últimos 30 a 60 días.</strong>',
};

const bestDiscounts: DebtFilter = {
  id: 'best_discounts',
  label: 'Melhores descontos',
  filterFn: (debts: FormattedDebt[]) =>
    debts.filter((debt) => {
      const debtDiscount = getDebtDiscount(debt);
      const percentageThreshold = 80;
      return debtDiscount >= percentageThreshold;
    }),
  desc: 'Descontos são ótimas oportunidades para quitar sua dívida, <strong>aproveite e faça uma boa negociação.</strong>',
};

const itauDiscounts: DebtFilter = {
  id: 'itau',
  label: 'Itaú',
  filterFn: (debts: FormattedDebt[]) => {
    const itauDebts =
      debts.filter((debt) => debt.partner.partnerIdentifier === 'itau') || [];

    const sortedByDiscount = itauDebts.sort(
      (a, b) => getDebtDiscount(b) - getDebtDiscount(a)
    );
    return sortedByDiscount;
  },
};

export const partnersFilters: DebtFilter[] = [
  itauDiscounts,
  createPartnerFilter('carrefour', 'Carrefour'),
  createPartnerFilter('ipanema', 'Ipanema'),
  createPartnerFilter('vivo', 'Vivo'),
  createPartnerFilter('credsystem', 'Credsystem'),
  createPartnerFilter('recovery', 'Recovery'),
];

export const baseFilters: DebtFilter[] = [negative, bestDiscounts];
