import { createContext, useState, useEffect, useRef, SetStateAction, Dispatch } from 'react';
import {
  PartnerSystems,
  SortingDebtResponse,
} from '@common/apis/sorting-debt/types';
import { formatSDResponseCache } from './helpers';

import { cacheSortingDebtIsValid } from '@common/hooks/useDebts/helpers';
import { useUserStore } from '@common/store/useUserStore';
import {
  legacyBase64Decrypt,
  legacyBase64Encrypt,
} from '@libs/utils/helpers/base64';
import constants from '@common/constants';
import { useRedline } from '@libs/redline';
import useApiSortingDebt from '@common/apis/sorting-debt/useApiSortingDebt';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import useAuth from '@common/hooks/useAuth';
import useDebtDiscountCountdown from '@common/hooks/useDebtDiscountCountdown';

import { DebtFilter, FormattedDebt } from './types';
import useDebtsFiltering from './hooks/useDebtsFiltering';
import { getCookie, removeCookie } from '@libs/utils/helpers/getSafeCookies';

import { base64Decrypt } from '@libs/utils/helpers/base64';
import { pageNames } from '@common/routes/pageNames';
import useGTM from '@common/hooks/useGTM';
import useEngageTrack from '@common/hooks/useEngageTrack';

import useDebtCardRedesignReneg from '@common/abtest/experiments/useDebtCardRedesignReneg';

interface DebtContextType {
  debtsList: FormattedDebt[];
  debtsListSemiSkeletons: any;
  skeletonsCards: any;
  isStatusPartial: boolean;
  noDebts: boolean;
  filteredDebts: FormattedDebt[];
  filterList: DebtFilter[];
  notFoundDebtsText?: string;
  clearFilter: () => void;
  clearNotFoundDebtsMsg: () => void;
  selectFilter: (filter: DebtFilter) => void;
  selectedFilter: DebtFilter | null;
  hasFilter: boolean;
  showFilterModal: boolean;
  showedFilterModal: boolean;
  closeFilterModal: () => void;
  countdownTimer: string;
  hasRedirectTo: boolean;
  setHasRedirectTo: Dispatch<SetStateAction<boolean>>;
}

// TODO - move all this logic to ./hooks/index.ts
export const DebtsContext = createContext<DebtContextType>({
  debtsList: [],
  debtsListSemiSkeletons: undefined,
  skeletonsCards: undefined,
  isStatusPartial: true,
  noDebts: false,
  filteredDebts: [],
  filterList: [],
  clearFilter: () => {},
  selectFilter: (filter: DebtFilter) => {},
  clearNotFoundDebtsMsg: () => {},
  selectedFilter: null,
  hasFilter: false,
  showFilterModal: false,
  closeFilterModal: () => {},
  showedFilterModal: false,
  countdownTimer: "",
  hasRedirectTo: false,
  setHasRedirectTo: () => {}
});

export const DebtsProvider = ({ children }: any) => {
  const { logOut, validToken } = useAuth();
  const user = useUserStore((state) => state.user);

  const { isMobile } = useDeviceSize();
  const GTM = useGTM();
  const { track: trackRedline } = useRedline();
  const engage = useEngageTrack();
  const { apiSortingDebtTrack } = useApiSortingDebt();

  const firstRequestSortingDebt = useRef(true);
  const isPollingActive = useRef(true);
  const [debtsPollingIntervalId, setDebtsPollingIntervalId] =
  useState<any>(null);
  const [debtsList, setDebtsList] = useState<FormattedDebt[]>([]);
  const [debtsListSemiSkeletons, setDebtsListSemiSkeletons] =
  useState<SortingDebtResponse>();
  const [skeletonsCards] = useState(() => [...Array(isMobile ? 1 : 4).keys()]);
  const [isStatusPartial, setIsStatusPartial] = useState<boolean>(true);
  const [noDebts, setNoDebts] = useState<boolean>(false);
  const [hasRedirectTo, setHasRedirectTo] = useState<boolean>(false);

  const {
    filteredDebts,
    filterList,
    clearFilter,
    selectFilter,
    selectedFilter,
    hasFilter,
    showFilterModal,
    closeFilterModal,
    showedFilterModal,
    notFoundDebtsText,
    clearNotFoundDebtsMsg,
  } = useDebtsFiltering({ fullDebtList: debtsList, isStatusPartial, user });

  const { resultIsCardA } = useDebtCardRedesignReneg()

  const { countdownTimer } = useDebtDiscountCountdown(isStatusPartial, noDebts, resultIsCardA)

  const filterSemiSkeletonCards = (
    responseSortingDebts: SortingDebtResponse
  ) => {
    return {
      ...responseSortingDebts,
      partnerSystems: responseSortingDebts?.partnerSystems?.filter(
        (partner) => partner?.debts?.length === 0
      ),
    };
  };


  const updateDebts = (currentResponseSortingDebts: any, debtlist: any) => {
    if (!currentResponseSortingDebts || !debtlist) return true;

    const hasMatchingId = (
      elementA: PartnerSystems,
      elementB: PartnerSystems
    ) => {
      return elementA?.id === elementB?.id;
    };

    const hasAllElementsMatching = (
      sourceList: PartnerSystems[],
      targetList: PartnerSystems[]
    ) => {
      return sourceList?.every((sourceElement) =>
        targetList?.some((targetElement) =>
          hasMatchingId(sourceElement, targetElement)
        )
      );
    };

    const hasAllDebtsInTheDebtList =
      hasAllElementsMatching(currentResponseSortingDebts, debtlist) &&
      hasAllElementsMatching(debtlist, currentResponseSortingDebts);

    return !hasAllDebtsInTheDebtList;
  };

  const handleRedirectToCookie = () => {
    const redirectToCookie = getCookie(constants.cookies.REDIRECT_TO);

    if (!redirectToCookie) return undefined;

    try {
      const decryptData = base64Decrypt(redirectToCookie);
      const redirectData = JSON.parse(decryptData);

      return redirectData;
    } catch {
      console.error("[handleRedirectToCookie] - Error decoding or parsing cookie 'redirect_to'");
      removeCookie(constants.cookies.REDIRECT_TO);

      return undefined;
    }
  };

  const formattingPartnersInDebts = (partnerSystems: PartnerSystems[]) => {
    const redirectTo = handleRedirectToCookie();

    const debtsWithPartner = partnerSystems?.reduce(
      (formattedDebts, partnerSystem) => {
        const { debts: partnerDebts, ...partnerProps } = partnerSystem;
        const formattedPartnerDebts = partnerDebts.map((debt) => {
          return {
            partner: partnerProps,
            ...debt,
            ...(!!redirectTo && { redirectTo })
          };
        });

        return [
          ...formattedDebts,
          ...formattedPartnerDebts
        ];
      },
      [] as FormattedDebt[]
    );

    return debtsWithPartner;
  };

  const handleUpdateDebtsList = (responseSortingDebts: SortingDebtResponse) => {
    setIsStatusPartial(responseSortingDebts.status === 'PARTIAL');

    const currentDebtsListSemiSkeletons =
      filterSemiSkeletonCards(responseSortingDebts);

    const updateDebtsSkeleton = updateDebts(
      currentDebtsListSemiSkeletons?.partnerSystems,
      debtsListSemiSkeletons?.partnerSystems
    );

    if (updateDebtsSkeleton)
      setDebtsListSemiSkeletons(currentDebtsListSemiSkeletons);

    const formattingSortingDebt = formattingPartnersInDebts(
      responseSortingDebts?.partnerSystems
    );

    sendEventDebtSimulated(formattingSortingDebt);
    sendEventDebtLocated(responseSortingDebts?.partnerSystems);

    setNoDebts(formattingSortingDebt?.length === 0);

    const updatedDebts = updateDebts(formattingSortingDebt, debtsList);
    if (updatedDebts) setDebtsList(formattingSortingDebt);
  };

  const getSortingDebtsInLocalStorage = () => {
    localStorage.removeItem(constants.debts.DEBT);
    localStorage.removeItem(constants.debts.PAYMENT_OPTION);

    const localDebts = localStorage.getItem(constants.debts.SORTING_DEBTS_RES);
    if (localDebts !== null) {
      const decryptedDebts = legacyBase64Decrypt(localDebts);
      const sortingDebtResponse = JSON.parse(decryptedDebts);

      const dateCacheSB = sortingDebtResponse?.date;
      if (!cacheSortingDebtIsValid(dateCacheSB)) return;

      handleUpdateDebtsList(sortingDebtResponse);
    }
  };

  const handleUnauthorized = async (data: any) => {
    const isValid = await validToken();

    if (!isValid || data?.error_slug === 'unauthorized') {
      logOut();


      isPollingActive.current = false;
      clearInterval(debtsPollingIntervalId);

      return window.location.href = pageNames.signin.path
    }
  };

  const startDebtsPollingAndUpdates = () => {
    if (!isPollingActive.current) return;

    apiSortingDebtTrack
      .send({
        customerIdHash: user.customerIdHash,
        document: user.documento,
      })
      .then((response: SortingDebtResponse | any) => {
        handleUpdateDebtsList(response);

        if (response?.status === 'COMPLETED') {
          isPollingActive.current = false;
          clearInterval(debtsPollingIntervalId);

          const formattedResponse = formatSDResponseCache(response);
          const responseEncrypt = legacyBase64Encrypt(
            JSON.stringify(formattedResponse)
          );
          localStorage.setItem(
            constants.debts.SORTING_DEBTS_RES,
            responseEncrypt
          );
        }
      })
      .catch(async (error) => {
        console.warn('[startDebtsPollingAndUpdates]: ', error);
        handleUnauthorized(error);
      });
  };

  const runFirstRequestSortingDebt = () => {
    if (firstRequestSortingDebt.current) {
      firstRequestSortingDebt.current = false;
      startDebtsPollingAndUpdates();
    }
  };

  const runPollingSortingDebt = () => {
    if (isPollingActive.current && !firstRequestSortingDebt.current) {
      const id = setInterval(startDebtsPollingAndUpdates, 2000);
      setDebtsPollingIntervalId(id);
    }
  };

  //#region EVENTS
  const sendEventDebtLocated = (partnerSystems: PartnerSystems[]) => {
    partnerSystems?.forEach((partnerSystem) => {
      trackRedline.debts.debtLocated({ partnerSystem }, () => {
        // this is a redline debounced event. code inside this callback will be debouced together.
        engage.sendDebtLocatedEvent();
        GTM.trigger.debtFound({dividaId: partnerSystem.id, partner: partnerSystem.name});
      });
    });
  };

  const sendEventDebtSimulated = (debts: any) => {
    debts?.forEach((debt: any) => {
      trackRedline.debts.debtSimulated({
        paymentOption: debt?.paymentOptions?.[0],
        debt: { ...debt, partner: debt.partner },
      });
    });
  };
  //#endregion EVENTS

  useEffect(() => {
    const isUserLoggedIn = !!user?.documento;
    const triggerOnlyOnPaths = ['minhas-dividas', 'monitoramento'];
    const currentPath = window?.location?.pathname;
    const currentUrlisValidForTrigger = triggerOnlyOnPaths.some((path) =>
      currentPath.includes(path)
    );

    if (isUserLoggedIn && currentUrlisValidForTrigger) {
      getSortingDebtsInLocalStorage();

      runFirstRequestSortingDebt();
      runPollingSortingDebt();

      return () => {
        clearInterval(debtsPollingIntervalId);
        setDebtsPollingIntervalId(null);
      };
    }
  }, [user, window?.location?.pathname]);

  return (
    <DebtsContext.Provider
      value={{
        debtsList,
        debtsListSemiSkeletons,
        skeletonsCards,
        isStatusPartial,
        noDebts,
        filteredDebts,
        filterList,
        clearFilter,
        selectFilter,
        selectedFilter,
        notFoundDebtsText,
        hasFilter,
        showFilterModal,
        closeFilterModal,
        showedFilterModal,
        clearNotFoundDebtsMsg,
        countdownTimer,
        hasRedirectTo,
        setHasRedirectTo
      }}
    >
      {children}
    </DebtsContext.Provider>
  );
};