import useApiEngage from '@common/apis/engage-track/useApiEngage';
import { AgreementSettledEvt, VisualizedDebtEvt } from '@common/apis/engage-track/types';

const useEngageTrack = () => {
  const { apiEngageTrackEvent } = useApiEngage();

  const sendEngageTrackEvent = (eventName: string, data: Record<string, any> = {}) => {
    const payload = {
      name: eventName,
      payload: data,
    };

    apiEngageTrackEvent.send(payload);
  };

  const engageEventNames = {
    DEBT_LOCATED: "debt_located",
    DEBT_VISUALIZED: "debt_visualized",
    AGREEMENT_SETTLED: "agreement_settled",
  };

  const sendDebtLocatedEvent = () => {
    sendEngageTrackEvent(engageEventNames.DEBT_LOCATED);
  };

  const sendDebtVisualizedEvent = (payload: VisualizedDebtEvt) => {
    sendEngageTrackEvent(engageEventNames.DEBT_VISUALIZED, payload);
  };

  const sendAgreementSettledEvent = (payload: AgreementSettledEvt) => {
    sendEngageTrackEvent(engageEventNames.AGREEMENT_SETTLED, payload);
  };

  return {
    sendDebtLocatedEvent,
    sendDebtVisualizedEvent,
    sendAgreementSettledEvent,
    engageEventNames,
  };
};

export default useEngageTrack;
