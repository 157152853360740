
// This file is generated automatically
// To edit see the file libs/atlas/scr/tasks/generateIcons.js
import Icon from "../index";

export default function IconLoader(props: any) {
  return (
    <Icon
      iconMarkup={`<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width='24' height='24' color='currentColor'><defs><linearGradient id='ac-loading' gradientUnits='userSpaceOnUse' x1='55' y1='46' x2='2' y2='46'><stop offset='0.1' stop-color='white'  stop-opacity='0'/><stop offset='1' stop-color='white' stop-opacity='1'/></linearGradient></defs><g stroke-width='7' stroke-linecap='round' fill='none'><path stroke='url(#ac-loading)' d='M4,32 c0,15,12,28,28,28c8,0,16-4,21-9'/><path stroke='currentColor' d='M60,32 C60,16,47.464,4,32,4S4,16,4,32'/></g></svg>`}
      iconName="IconLoader"
      iconSize={props.size}
      iconColor={props.color}
      {...props}
    />
  );
}

