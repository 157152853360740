import { lazy } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { pageNames } from '@common/routes/pageNames';

const ErrorKba = lazy(() => import('@admission/pages/Error/Kba'));
const ErrorKbaBigDataCorp = lazy(() => import('@admission/pages/Error/KbaBigDataCorp'));
const ErrorMinor = lazy(() => import('@admission/pages/Error/Minor'));
const ErrorNoData = lazy(() => import('@admission/pages/Error/NoData'));
const PublicPerson = lazy(() => import('@admission/pages/Error/PublicPerson'));
const RecoveryPassword = lazy(() => import('@admission/pages/RecoveryPassword'));
const SignIn = lazy(() => import('@admission/pages/Signin'));
const Signup = lazy(() => import('@admission/pages/Signup'));
const ImplicitSignIn = lazy(() => import('@admission/pages/ImplicitSignin'));
const AccountCanceled = lazy(() => import('@admission/pages/AccountCanceled'))
const RedefinePassword = lazy(() =>  import("@admission/pages/RedefinePassword"))

export function Router() {
  const location = useLocation();

  return (
    <>
      <Route
        path={pageNames.home.path}
        element={<Navigate to={`${pageNames.signin.path}${location?.search}`} />}
      />
      <Route path={pageNames.signin.path} element={<SignIn />} />
      <Route path={pageNames.inplicitSignin.path} element={<ImplicitSignIn />} />
      <Route path={pageNames.signup.path} element={<Signup />} />
      <Route path={pageNames.recoveryPassword.path} element={<RecoveryPassword />} />
      <Route path={pageNames.redefinePassword.path} element={<RedefinePassword />} />
      <Route path={pageNames.accountCanceled.path} element={<AccountCanceled/>}></Route>
      <Route path={pageNames.errorNoData.path} element={<ErrorNoData />} />
      <Route path={pageNames.errorMinor.path} element={<ErrorMinor />} />
      <Route
        path={pageNames.errorPublicPerson.path}
        element={<PublicPerson />}
      />
      <Route path={pageNames.errorKba.path} element={<ErrorKba />} />
      <Route path={pageNames.errorKbaBigDataCorp.path} element={<ErrorKbaBigDataCorp />} />
    </>
  );
}
