import classNames from "classnames";
import './styles.scss'

interface IconProps {
  iconMarkup?: string;
  iconName?: string;
  iconSize?: 'large'
  iconColor?: 'dark'
}

const Icon: React.FC<IconProps> = ({ iconMarkup, iconName, iconSize, iconColor }) => {
  const componentClass = classNames("ac-icon", {
    [`ac-icon--${iconName?.toLocaleLowerCase()}`]: !!iconName,
    "ac-icon--color-dark": iconColor === 'dark',
    "ac-icon--size-large": iconSize === 'large',
  });

  return (
    <div
      className={componentClass}
      dangerouslySetInnerHTML={{
        __html: iconMarkup || "",
      }}
    />
  );
};

export default Icon;
