import { getCookie } from '@libs/utils/helpers/getSafeCookies';

/**
 * Template for A/B testing with two variants (DX and Z).
 * Checks if the user is in the DX variant.
 * @returns {Object} An object containing the function to check the DX variant.
 */
const useAtivosGroupedDebts = () => {
  const testCookieName = 'dx-ativos-grouped-debts';

  const variants = {
    '0': 'grouped-debts',
    '1': 'ungrouped-debts'
  };

  const resultIsGroupedDebts = () => {
    const currentResult = getCookie(testCookieName, false);

    if (!currentResult) return true;

    return currentResult.startsWith(variants[0]);
  };

  return {
    resultIsGroupedDebts,
  };
};

export default useAtivosGroupedDebts;
