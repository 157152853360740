/* import { StrictMode } from 'react'; */
import * as ReactDOM from 'react-dom/client';
import { RedlineProvider } from '@libs/redline';

import App from './App';

window.addEventListener('vite:preloadError', () => {
  window.location.reload() /* https://vitejs.dev/guide/build#load-error-handling */
})
 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // TODO: descomentar esse trecho
  // <StrictMode>
    <RedlineProvider
      writeKey={import.meta.env.VITE_REDLINE_WRITE_KEY}
      mode={import.meta.env.VITE_ENV}
      appName={import.meta.env.VITE_REDLINE_APP_NAME}
      appVersion={import.meta.env.VITE_DEPLOY_VERSION}
      logs={false}
    >
      <App />
    </RedlineProvider>
  // </StrictMode>
);
