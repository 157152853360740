import { InitialStateProps } from './types';

export const initialState: InitialStateProps = {
  auth: {
    isAuthenticated: false,
    token: '',
    exp: '',
  },
  user: {
    celular: '',
    customerIdHash: '',
    cutOffValue: '',
    dataNascimento: '',
    documento: '',
    email: '',
    id: 0,
    newsletter: false,
    nome: '',
    primeiroNome: '',
    nomeSocial: '',
    registrado: '',
    sexo: '',
    telefoneValidado: '',
    termsOfUse: '',
    termsOfUseVersion: 0,
    uuid: '',
    admin: false,
  },
  findUser: {
    celular: '',
    documento: '',
    registrado: false,
    cutOffValue: 0,
    customerIdHash: '',
    email: '',
    canRegister: {
      politicallyExposed: false,
      minor: false,
      noData: false,
    },
  },
  sortingHat: {
    acTree: {
      enabled: false,
      prevent_continue: false,
      result: {
        decisions: [
          {
            category: '',
            id: '',
            slug: '',
            partner: '',
            preApproved: false,
            featured: [],
            dashboard: false,
          },
        ],
      },
      result_type: '',
    },
  },
  score: {},
  sortingDebt: {
    status: 'PARTIAL',
    partnerSystems: [],
    result: []
  }
};
