import { useEffect, useState, useRef } from 'react';
import constants from '@common/constants';

const useDebtDiscountCountdown = (
  isStatusPartial: boolean,
  noDebts: boolean,
  isDefaultCardVariant: boolean
) => {
  const { COUNTDOWN_STARTED_AT } = constants.debts;
  const [shouldRestartTimer, setShouldRestartTimer] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const initialTimer = '00:15:00';

  const getTimeRemaining = (deadline: Date) => {
    const total = deadline.getTime() - new Date().getTime();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };

  const clearTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  const getDeadline = () => {
    const startTime = localStorage.getItem(COUNTDOWN_STARTED_AT);
    if (!startTime) {
      clearTimer();
      return;
    }

    const deadline = new Date(startTime);
    deadline.setMinutes(deadline.getMinutes() + 15);

    return deadline;
  };

  const formattedTimer = (minutes: number, seconds: number) => {
    const min = minutes.toString().padStart(2, '0');
    const sec = seconds.toString().padStart(2, '0');

    if (!isDefaultCardVariant) {
      return `${min}m ${sec}s`;
    }

    return `00:${min}:${sec}`;
  };

  const [countdownTimer, setCountdownTimer] = useState<any>((prev: string) => {
    const deadline = getDeadline();
    if (!deadline) return (prev = initialTimer);

    const { minutes, seconds } = getTimeRemaining(deadline);
    return (prev = formattedTimer(minutes, seconds));
  });

  const startTimer = (deadline: Date) => {
    const { total, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setCountdownTimer(formattedTimer(minutes, seconds));

      if (shouldRestartTimer) setShouldRestartTimer(false);
    } else {
      localStorage.removeItem(COUNTDOWN_STARTED_AT);
      setShouldRestartTimer(true);
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem(COUNTDOWN_STARTED_AT) &&
      !isStatusPartial &&
      !noDebts
    ) {
      localStorage.setItem(COUNTDOWN_STARTED_AT, new Date().toString());
    }

    const interval = setInterval(() => {
      const deadline = getDeadline();
      if (!deadline) {
        setCountdownTimer(initialTimer);
        return;
      }

      startTimer(deadline);
    }, 1000);
    timerRef.current = interval;

    return () => clearTimer();
  }, [isStatusPartial, shouldRestartTimer]);

  return {
    countdownTimer,
  };
};

export default useDebtDiscountCountdown;
